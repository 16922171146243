<template>
    <transition name="fade">
        <div v-if="display" class="page-backdrop" @click="close"></div>
    </transition>
    <transition name="fade">
        <div v-if="display" class="modal-service">
            <button class="close-button" @click="close">&times;</button>
            <img :src="shop.wallpaper || '/img/driverwise_banner.jpg'" alt="shop.name" class="img-fluid img-rounded"><br>
            <img :src="shop.logo || '/img/driverwise_banner.jpg'" alt="shop.name" class="img-fluid img-rounded shop-logo">
            <div class="container-fluid">
                <div class="col-12 mt-4">
                    <div class="col-10 offset-1">
                        <div class="row">
                            <div class="col-12 mt-1">
                                <h3 class="text-center">Pedido de orçamento</h3>
                                <hr>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <form @submit.prevent="sendBudgetRequest">
                                    <div class="form-floating mt-3">
                                        <select v-model="selectedVehicle" class="form-control" id="vehicle" required>
                                            <option value="disabled" disabled selected>Seleccione a viatura</option>
                                            <option v-for="vehicle in userVehicles" :key="vehicle.id" :value="vehicle.id">{{ vehicle.brand.name }} {{ vehicle.model }} {{ vehicle.subModel }} - {{ vehicle.licensePlate }}</option>
                                        </select>
                                        <label for="vehicle">Escolha a viatura <span class="text-danger">*</span></label>
                                    </div>
                                    <div class="form-floating mt-3">
                                        <select v-model="selectedService" class="form-control" id="service" required>
                                            <option value="disabled" disabled selected>Seleccione o serviço desejado</option>
                                            <option v-for="service in shop.services" :key="service.id" :value="service.id">{{ service.description }}</option>
                                        </select>
                                        <label for="service">Escolha o serviço <span class="text-danger">*</span></label>
                                    </div>
                                    <div class="form-floating mt-3">
                                        <textarea class="form-control" id="message" placeholder="Mensagem" rows="5" style="height:100%" v-model="message" required></textarea>
                                        <label for="message">Observações <span class="text-danger">*</span></label>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div class="row mt-5">
                            <div class="col-12 text-center">
                                <button @click="close" class="btn btn-danger">Cancelar</button>
                                <button class="btn btn-primary" style="margin-left: 10px;" @click="sendBudgetRequest">Enviar Pedido</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>
<script lang="ts">
import { SearchService } from '@/services/searchService';
import { ShopService } from '@/services/Shop/ShopService';
import { UserService } from '@/services/userService';
import { VehiclesService } from '@/services/vehiclesService';
import { defineComponent, ref, nextTick } from 'vue';

export default defineComponent({
    name: 'budgetRequestModal',
    props: {
        shop: {
            type: Object,
            required: true,
            immediate: true
        }
    },
    data() {
        return {
            message: "",
            selectedService: "disabled",
            selectedVehicle: "disabled",
            userVehicles: [] as any[],
            display: false
        }
    },
    mounted() {
        this.display = true;
        VehiclesService.obtainVehicles().then((vehicles) => {
            if (!vehicles) return;
            this.userVehicles = vehicles;
        });
    },
    methods: {
        sendBudgetRequest() {
            if (this.selectedService === "disabled" || this.selectedVehicle === "disabled" || this.message === "") {
                this.display = false;
                this.$swal.fire({
                    icon: 'error',
                    title: 'Erro',
                    text: 'Por favor, preencha todos os campos',
                    showConfirmButton: true,
                    confirmButtonText: "Ok",
                    confirmButtonColor: 'var(--primary-color)',
                    // Make sure z-index is higher than the modal
                    backdrop: true,
                }).then(() => {
                    this.display = true;
                });
                return;
            }

            this.display = false;
            // Show "A enviar pedido de orçamento..."
            this.$swal.fire({
                html: '<div class="container-fluid text-center pt-5 pb-5"><div class="spinner"></div></div>',
                showConfirmButton: false,
                allowOutsideClick: false,
                backdrop: true,
            });

            UserService.sendBudgetRequest(this.shop.id, parseInt(this.selectedService), parseInt(this.selectedVehicle), this.message).then(() => {
                this.$swal.close();
                this.$swal.fire({
                    icon: 'success',
                    title: 'Pedido de orçamento enviado',
                    text: 'O seu pedido de orçamento foi enviado com sucesso! A redereccionar para a conversa...',
                    showConfirmButton: false,
                    confirmButtonText: "Ok",
                    confirmButtonColor: 'var(--primary-color)',
                    backdrop: true,
                    showProgressBar: true,
                    timer: 2000,
                }).then(() => {
                    this.display = true;
                    this.$router.push({ name: 'chat', params: { shopId: this.shop.id } });
                    this.$swal.close();
                    this.close();
                });
            }).catch(() => {
                this.$swal.close();
                this.$swal.fire({
                    icon: 'error',
                    title: 'Erro',
                    text: 'Ocorreu um erro ao enviar o pedido de orçamento. Por favor, tente novamente.',
                    showConfirmButton: true,
                    confirmButtonText: "Ok",
                    confirmButtonColor: 'var(--primary-color)',
                    // Make sure z-index is higher than the modal
                    backdrop: true,
                }).then(() => {
                    this.display = true;
                });
            });
        },
        close() {
            this.display = false;
            nextTick(() => {
                setTimeout(() => {
                    this.$emit('closed');
                }, 300);
            });
        },
        beforeEnter(el: HTMLElement) {
            el.style.opacity = '0';
        },
        enter(el: HTMLElement, done: () => void) {
            el.offsetHeight; // trigger reflow
            el.style.transition = 'opacity 0.3s';
            el.style.opacity = '1';
            done();
        },
        leave(el: HTMLElement, done: () => void) {
            el.style.transition = 'opacity 0.3s';
            el.style.opacity = '0';
            done();
        }
    }
});
</script>
<style scoped>
.fade-enter-active, .fade-leave-active {
    transition: opacity 0.2s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
    opacity: 0;
}

.modal-service {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    border-radius: 10px;
    z-index: 5001;
    width: 80%;
    max-width: 800px;
    max-height: 700px;
    overflow-y: auto;
    overflow-x: hidden;
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
    padding-bottom: 20px;
}

.modal-service img {
    width: 100%;
    height: 100px;
    object-fit: cover;
    object-position: center;
}

.modal-service img:not(.shop-logo) {
    border-bottom: 1px solid #000000;
}

.modal-service img.shop-logo {
    width: 120px;
    object-fit: cover;
    object-position: center;
    position: absolute;
    top: 35px;
    left: calc(50% - 60px);
}

.page-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(10px);
    z-index: 5000;
}

h3 {
    color: #EF4E22;
    text-align: center;
}

.float-right {
    float: right;
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border: none;
    font-size: 24px;
    color: #ffffff;
    cursor: pointer;
    z-index: 1002;
}

.close-button:hover {
    color: #EF4E22;
}
</style>
