<template>
    <div>
      <div class="header">
        <img src="https://alpha.driverwise.pt/img/logobranco.png" alt="DriverWise Logo" />
      </div>
  
      <div class="container">
        <div class="content">
          <h1>Disponível Brevemente</h1>
          <p>
            A melhor APP de gestão da sua viatura no mercado!<br />
            Siga-nos no
            <a
              href="https://www.instagram.com/driverwise.pt/"
              target="_blank"
              class="instagram-link"
              >Instagram</a
            >
            para saber as novidades!
          </p>
        </div>
      </div>
    </div>
  </template>
  
  <script lang="ts">
  import { defineComponent } from 'vue';
  
  export default defineComponent({
    name: 'HomePage',
    mounted() {
        document.title = 'DriverWise - Brevemente';
        // Check if the user is logged in
        const token = localStorage.getItem('token');
        if (token) {
          this.$router.push({ name: 'home' });
        }
    },
  });
  </script>
  
  <style scoped>
  body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f5f5f5;
  }
  .container {
    text-align: center;
    padding: 50px;
  }
  .header {
    background-color: #f24822;
    padding: 20px;
    color: white;
  }
  .header img {
    max-width: 150px;
  }
  .content {
    margin: 50px auto;
    padding: 20px;
    background-color: white;
    border-radius: 10px;
    max-width: 600px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  h1 {
    color: #333;
  }
  p {
    font-size: 18px;
    color: #666;
  }
  .instagram-link {
    color: #ef4e23;
    text-decoration: none;
  }
  footer {
    background-color: #333;
    color: white;
    padding: 10px;
    position: fixed;
    bottom: 0;
    width: 100%;
    text-align: center;
  }
  </style>
  