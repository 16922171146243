import axios from "axios";

// Define API URL
const API_URL = process.env.VUE_APP_API_URL;


// Define axios instance
const axiosInstance = axios.create({
    baseURL: API_URL,
    headers: {
        'Content-Type': 'application/json',
    },
});


// Create API class for authentication
export class AuthService {
    static async getUserName() {
        const token = localStorage.getItem('token');
        if (!token) {
            return '';
        }
        const response = await axiosInstance.get('/v1/user', {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
        });
        return response.data.firstName;
    }
    static async isAuthenticated() {
        const token = localStorage.getItem('token');
        if (!token) {
            return false;
        }
        try {
            const response = await axiosInstance.get('/v1/auth/is-authenticated', {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });

            return response.status === 200;
        } catch (error) {
            return false;
        }
    }
    // Register a new user
    static async register(firstName: string, lastName: string, email: string, password: string) {
        const response = await axiosInstance.post('/v1/auth/register', { firstName: firstName, lastName: lastName, email: email, password: password});
        
        const status = await response.data.status;
        if (status === 'EMAIL_EXISTS') {
                return 2;
        }

        if (status === 'ERROR') {
            return 1;
        }

        return 0;
    }

    static async login(email: string, password: string, recaptchaToken: string) {
        const response = await axiosInstance.post('/v1/auth/login', { email: email, password: password, recaptchaToken: recaptchaToken });

        if (response.status === 400) {
            throw new Error('Invalid credentials');
        }
        return await response.data;
    }

    static async loginShop(email: string, password: string, recaptchaToken: string) {
        const response = await axiosInstance.post('/v1/auth/login-shop', { email: email, password: password, recaptchaToken: recaptchaToken });

        if (response.status === 400) {
            throw new Error('Invalid credentials');
        }
        return await response.data;
    }

    static async registerShop(
        firstName: string,
        lastName: string,
        email: string,
        password: string,
        shopName: string,
        shopType: string,
        shopVat: string,
        shopAddress: string,
        shopAddress2: string,
        shopCity: string,
        shopState: string,
        shopZip: string,
        shopPhone: string,
        shopEmail: string
    ) {
        const response = await axiosInstance.post('/v1/auth/register-shop', {
            firstName: firstName,
            lastName: lastName,
            email: email,
            password: password,
            shopName: shopName,
            shopType: shopType,
            shopVat: shopVat,
            shopAddress: shopAddress,
            shopAddress2: shopAddress2,
            shopCity: shopCity,
            shopState: shopState,
            shopZip: shopZip,
            shopPhone: shopPhone,
            shopEmail: shopEmail
        });

        const status = await response.data.status;
        switch (status) {
            case 'SHOP_REGISTERED':
                return 3;
            case 'EMAIL_EXISTS':
                return 2;
            case 'ERROR':
                return 1;
            default:
                return 0;
        }
    }
}