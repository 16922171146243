import axios from "axios";
import { ShopInfo } from "@/dtos/shopService/shopInfoInterface";
import { updateShopInfoDTO } from "@/dtos/shopService/updateShopInfoDTO";
import { CustomersInterfaceDTO } from "@/dtos/shopService/customersInterfaceDTO";

// Define API URL
const API_URL = process.env.VUE_APP_API_URL;


// Define axios instance
const axiosInstance = axios.create({
    baseURL: API_URL,
    headers: {
        'Content-Type': 'application/json',
    },
});

export class ShopService {
    static async getWorkerPermission() {
        const token = localStorage.getItem('token');
        if (!token) {
            return false;
        }
        try {
            const response = await axiosInstance.get('/v1/shop/worker/permissions', {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });
            return response.status === 200 ? await response.data : false;
        } catch (error) {
            return null;
        }
    }
        
    static async shopInfo() {
        const token = localStorage.getItem('token');
        if (!token) {
            return false;
        }
        try {
            const response = await axiosInstance.get('/v1/shop/info', {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });
            return response.status === 200 ? await response.data as ShopInfo | null : false;
        } catch (error) {
            return false;
        }
    }

    static async obtainShopServices() {
        const token = localStorage.getItem('token');
        if (!token) {
            return false;
        }

        try {
            const response = await axiosInstance.get(`/v1/shop/services`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });
            return response.status === 200 ? await response.data : false;
        } catch (error) {
            return false;
        }
    }

    static async obtainShopServicesCategories() {
        const token = localStorage.getItem('token');
        if (!token) {
            return false;
        }

        try {
            const response = await axiosInstance.get(`/v1/shop/services/categories`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });
            return response.status === 200 ? await response.data : false;
        } catch (error) {
            return false;
        }
    }

    static async updateShopInfo(shopInfo: updateShopInfoDTO) {
        const token = localStorage.getItem('token');
        if (!token) {
            return false;
        }

        try {
            const response = await axiosInstance.post(`/v1/shop/info`, shopInfo, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });
            return response.status === 200 ? true : false;
        } catch (error) {
            return false;
        }
    }

    static async deleteShopService(serviceId: number) {
        const token = localStorage.getItem('token');
        if (!token) {
            return false;
        }

        try {
            const response = await axiosInstance.delete(`/v1/shop/services/${serviceId}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });
            return response.status === 200 ? true : false;
        } catch (error) {
            return false;
        }
    }

    static async createShopService(serviceData: any) {
        const token = localStorage.getItem('token');
        if (!token) {
            return false;
        }

        try {
            const response = await axiosInstance.post(`/v1/shop/services`, serviceData, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });
            return response.status === 200 ? true : false;
        } catch (error) {
            return false;
        }
    }

    static async addWorker(workerData: any) {
        const token = localStorage.getItem('token');
        if (!token) {
            return false;
        }

        try {
            const response = await axiosInstance.post(`/v1/worker`, workerData, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });
            return response.status === 200 ? true : false;
        } catch (error) {
            return false;
        }
    }           

    static async deleteWorker(workerId: number) {
        const token = localStorage.getItem('token');
        if (!token) {
            return false;
        }

        try {
            const response = await axiosInstance.delete(`/v1/worker/${workerId}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });
            return response.status === 200 ? true : false;
        } catch (error) {
            return false;
        }
    }

    static async getWorker(workerId: number) {
        const token = localStorage.getItem('token');
        if (!token) {
            return false;
        }

        try {
            const response = await axiosInstance.get(`/v1/worker/${workerId}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });
            return response.status === 200 ? await response.data : false;
        } catch (error) {
            return false;
        }
    }

    static async updateWorker(
        workerid: number,
        firstName: string,
        lastName: string,
        phone: string,
        birthDate: string,
        contractStart: string,
        photo: string,
        companyRole: string,
        permission: string
   ) {
        const token = localStorage.getItem('token');
        if (!token) {
            return false;
        }

        try {
            const response = await axiosInstance.post(`/v1/worker/${workerid}`, {
                firstName,
                lastName,
                phone,
                birthDate,
                contractStart,
                photo,
                companyRole,
                permission,
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });
            return response.status === 200 ? true : false;
        } catch (error) {
            return false;
        }
   }

   static async getCustomers() {
        const token = localStorage.getItem('token');
        if (!token) {
            return false;
        }

        try {
            const response = await axiosInstance.get(`/v1/customers`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });
            return response.status === 200 ? await response.data as CustomersInterfaceDTO[] : false;
        } catch (error) {
            return false;
        }
   }

   static async addCustomer(name: string, email: string, phone: string, address: string, country: string, nif: string) {
        const token = localStorage.getItem('token');
        if (!token) {
            return false;
        }

        try {
            const response = await axiosInstance.post(`/v1/customers`, {
                name,
                email,
                phone,
                address,
                country,
                nif,
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });
            return response.status === 200 ? true : false;
        } catch (error) {
            return false;
        }
   }

   static async updateCustomer(customerId: number, name: string, email: string, phone: string, address: string, country:string, nif: string) {
        const token = localStorage.getItem('token');
        if (!token) {
            return false;
        }

        try {
            const response = await axiosInstance.post(`/v1/customers/${customerId}`, {
                name,
                email,
                phone,
                address,
                country,
                nif,
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });
            return response.status === 200 ? true : false;
        } catch (error) {
            return false;
        }
   }

   static async getCustomer(customerId: number) {
        const token = localStorage.getItem('token');
        if (!token) {
            return false;
        }

        try {
            const response = await axiosInstance.get(`/v1/customers/${customerId}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });
            return response.status === 200 ? await response.data as CustomersInterfaceDTO : false;
        } catch (error) {
            return false;
        }
   }

   static async deleteCustomer(customerId: number) {
        const token = localStorage.getItem('token');
        if (!token) {
            return false;
        }

        try {
            const response = await axiosInstance.delete(`/v1/customers/${customerId}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });
            return response.status === 200 ? true : false;
        } catch (error) {
            return false;
        }
   }
}