import { Vehicle } from "@/dtos/userService/vehicleInterface";
import { UploadedImage } from "@/dtos/userService/vehicleInterface";
import { Brands } from "@/dtos/vehicleService/brandsInterface";
import { InsertVehicleDTO } from "@/dtos/vehicleService/insertVehicleDTO";
import axios from "axios";

// Define API URL
const API_URL = process.env.VUE_APP_API_URL;


// Define axios instance
const axiosInstance = axios.create({
    baseURL: API_URL,
    headers: {
        'Content-Type': 'application/json',
    },
});

// Create API class for authentication
export class VehiclesService {
    static async obtainVehicles() {
        const token = localStorage.getItem('token');
        if (!token) {
            return false;
        }
        try {
            const response = await axiosInstance.get('/v1/user/vehicles', {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });

            return response.status === 200 ? await response.data as Vehicle[] : false;
        } catch (error) {
            return false;
        }
    }

    static async obtainBrands() {
        const token = localStorage.getItem('token');
        if (!token) {
            return false;
        }
        try {
            const response = await axiosInstance.get('/v1/vehicle/brands/all', {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });

            return response.status === 200 ? await response.data as Brands[] : false;
        } catch (error) {
            return false;
        }
    }

    static async obtainVehicleByLicensePlate(licensePlate: string) {
        const token = localStorage.getItem('token');
        if (!token) {
            return false;
        }
        try {
            const response = await axiosInstance.post('/v1/vehicle/find/license-plate', {
                licensePlate,
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });

            // If response is 200 BUT response.data contains {"nok": "IN_USE"}, then the license plate is already in use
            if (response.status === 200 && response.data.nok === 'IN_USE') {
                return 'IN_USE';
            }

            return response.status === 200 ? await response.data as false | Vehicle : false;
        } catch (error) {
            return false;
        }
    }

    static async updateVehicleImage(vehicleId: number, image: string) {
        const token = localStorage.getItem('token');
        if (!token) {
            return false;
        }
        try {
            const response = await axiosInstance.post('/v1/vehicle/image/update', {
                vehicleId,
                image: image,
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });

            return response.status === 200 ? true : false;
        } catch (error) {
            return false;
        }
    }

    static async uploadVehicleImage(image: string) {
        const token = localStorage.getItem('token');
        if (!token) {
            return false;
        }
        try {
            const response = await axiosInstance.post('/v1/vehicle/image/upload', {
                image: image,
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });

            return response.status === 200 ? await response.data as UploadedImage : false;
        } catch (error) {
            return false;
        }
    }

    static async insertVehicle(vehicleData: InsertVehicleDTO) {
        const token = localStorage.getItem('token');
        if (!token) {
            return false;
        }
        try {
            const response = await axiosInstance.post('/v1/vehicle/insert', vehicleData, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });

            return response.status === 200 ? await response.data as Vehicle : false;
        } catch (error) {
            return false;
        }
    }

    static async setDefaultVehicle(vehicleId: number) {
        const token = localStorage.getItem('token');
        if (!token) {
            return false;
        }
        try {
            const response = await axiosInstance.post('/v1/vehicle/set-default', {
                vehicleId,
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });

            return response.status === 200 ? true : false;
        } catch (error) {
            return false;
        }
    }

    static async obtainVehicleById(vehicleId: number) {
        const token = localStorage.getItem('token');
        if (!token) {
            return false;
        }
        try {
            const response = await axiosInstance.get(`/v1/vehicle/${vehicleId}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });

            return response.status === 200 ? await response.data as Vehicle : false;
        } catch (error) {
            return false;
        }
    }

    static async updateVehicleData(vehicleId: number, vehicleData: InsertVehicleDTO) {
        const token = localStorage.getItem('token');
        if (!token) {
            return false;
        }
        try {
            const response = await axiosInstance.post(`/v1/vehicle/update/${vehicleId}`, {
                vehicleData
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });

            return response.status === 200 ? true : false;
        } catch (error) {
            return false;
        }
    }

    static async searchVehicleByLicensePlateOrVIN(vehicleLicenseVIN: string) {
        const token = localStorage.getItem('token');
        if (!token) {
            return false;
        }
        try {
            const response = await axiosInstance.post('/v1/schedules/vehicle/search', {
                vehicleLicenseVIN,
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });

            return response.status === 200 ? await response.data as Vehicle : false;
        } catch (error) {
            return false;
        }
    }

}