<template>
    <transition name="fade">
        <div v-if="display" class="page-backdrop" @click="close"></div>
    </transition>
    <transition name="fade">
        <div v-if="display" class="modal-service">
            <button class="close-button" @click="close">&times;</button>
            <img :src="shop.wallpaper || '/img/driverwise_banner.jpg'" alt="shop.name" class="img-fluid img-rounded"><br>
            <img :src="shop.logo || '/img/driverwise_banner.jpg'" alt="shop.name" class="img-fluid img-rounded shop-logo">
            <div class="container-fluid">
                <div class="col-12 mt-4">
                    <div class="col-10 offset-1">
                        <div class="row">
                            <div class="col-12 mt-1">
                                <h3 class="text-center">{{ service.name }}</h3>
                                <hr>
                                <p v-html="formatDescription(service.description)"></p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 text-center">
                                <button @click="close" class="btn btn-danger">Fechar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>
<script lang="ts">
import { defineComponent, ref, nextTick } from 'vue';

export default defineComponent({
    name: 'serviceOverview',
    props: {
        service: {
            type: Object as () => { name: string; description: string },
            required: true
        },
        shop: {
            type: Object as () => { wallpaper?: string; logo?: string },
            required: true
        }
    },
    data() {
        return {
            display: false
        }
    },
    mounted() {
        setTimeout(() => {
            this.display = true;
        }, 0);
    },
    methods: {
        close() {
            this.display = false;
            // Wait for the transition to complete before emitting
            nextTick(() => {
                setTimeout(() => {
                    this.$emit('closed');
                }, 300); // Match this with the duration of your fade-out transition
            });
        },
        formatDescription(text: string) {
            let formattedText = text.replace(/\n/g, '<br>');
            formattedText = formattedText.replace(/\[b\]/g, '<b>')
                                        .replace(/\[\/b\]/g, '</b>');
            
            return formattedText;
        },
        beforeEnter(el: HTMLElement) {
            el.style.opacity = '0';
        },
        enter(el: HTMLElement, done: () => void) {
            el.offsetHeight; // trigger reflow
            el.style.transition = 'opacity 0.3s';
            el.style.opacity = '1';
            done();
        },
        leave(el: HTMLElement, done: () => void) {
            el.style.transition = 'opacity 0.3s';
            el.style.opacity = '0';
            done();
        }
    }
});
</script>
<style scoped>
.fade-enter-active, .fade-leave-active {
    transition: opacity 0.2s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
    opacity: 0;
}

.modal-service {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    border-radius: 10px;
    z-index: 5001;
    width: 80%;
    max-width: 800px;
    max-height: 700px;
    overflow-y: auto;
    overflow-x: hidden;
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
    padding-bottom: 20px;
}

.modal-service img {
    width: 100%;
    height: 100px;
    object-fit: cover;
    object-position: center;
}

.modal-service img:not(.shop-logo) {
    border-bottom: 1px solid #000000;
}

.modal-service img.shop-logo {
    width: 120px;
    object-fit: cover;
    object-position: center;
    position: absolute;
    top: 35px;
    left: calc(50% - 60px);
}

.page-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(10px);
    z-index: 5000;
}

h3 {
    color: #EF4E22;
    text-align: center;
}

.float-right {
    float: right;
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border: none;
    font-size: 24px;
    color: #ffffff;
    cursor: pointer;
    z-index: 1002;
}

.close-button:hover {
    color: #EF4E22;
}
</style>
