import { schedulePayload } from "@/dtos/userService/schedulesPayload";
import { Vehicle } from "@/dtos/userService/vehicleInterface";
import { ShopProfile } from "@/interfaces/shopProfileInterface";
import axios from "axios";

// Define API URL
const API_URL = process.env.VUE_APP_API_URL;


// Define axios instance
const axiosInstance = axios.create({
    baseURL: API_URL,
    headers: {
        'Content-Type': 'application/json',
    },
});

// Create API class for authentication
export class UserService {
    async obtainDefaultVehicle() {
        const token = localStorage.getItem('token');
        if (!token) {
            return false;
        }
        try {
            const response = await axiosInstance.get('/v1/default-car', {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });

            return response.status === 200 ? await response.data as Vehicle : false;
        } catch (error) {
            return false;
        }
    }
    static async obtainVehicles() {
        const token = localStorage.getItem('token');
        if (!token) {
            return false;
        }
        try {
            const response = await axiosInstance.get('/v1/user/vehicles', {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });

            return response.status === 200 ? await response.data as Vehicle[] : false;
        } catch (error) {
            return false;
        }
    }

    static async obtainUserDetails() {
        const token = localStorage.getItem('token');
        if (!token) {
            return false;
        }
        try {
            const response = await axiosInstance.get('/v1/user', {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });
            return response.status === 200 ? await response.data : false;
        } catch (error) {
            return false;
        }
    }

    static async updateUserInformation(firstName: string, lastName: string, phone: string) {
        const token = localStorage.getItem('token');
        if (!token) {
            return false;
        }
        try {
            const response = await axiosInstance.post('/v1/user/update-personal-information', {
                firstName,
                lastName,
                phone,
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });
            return response.status === 200;
        } catch (error) {
            return false;
        }
    }

    static async updateBillingInformation(address1: string, address2: string | null, city: string, state: string, zip: string) {
        const token = localStorage.getItem('token');
        if (!token) {
            return false;
        }
        try {
            const response = await axiosInstance.post('/v1/user/update-billing-information', {
                address1,
                address2,
                city,
                state,
                zip,
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });
            return response.status === 200;
        } catch (error) {
            return false;
        }
    }

    static async changePassword(oldPassword: string, newPassword: string) {
        const token = localStorage.getItem('token');
        if (!token) {
            return false;
        }
        try {
            const response = await axiosInstance.post('/v1/user/password-change', {
                oldPassword,
                newPassword,
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });
            return response.status === 200;
        } catch (error) {
            return false;
        }
    }

    static async isFavoriteShop(shopId: number) {
        const token = localStorage.getItem('token');
        if (!token) {
            return false;
        }
        try {
            const response = await axiosInstance.get(`/v1/user/shop/${shopId}/is-favorite`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });
            return response.status === 200 ? await response.data.isFavorite : false;
        } catch (error) {
            return false;
        }
    }

    static async toggleFavoriteShop(shopId: number) {
        const token = localStorage.getItem('token');
        if (!token) {
            return false;
        }
        try {
            const response = await axiosInstance.get(`/v1/user/shop/${shopId}/toggle-favorite`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });
            return response.status === 200 ? await response.data.isFavorite : false;
        } catch (error) {
            return false;
        }
    }

    static async hasUnreadMessages() {
        const token = localStorage.getItem('token');
        if (!token) {
            return false;
        }
        try {
            const response = await axiosInstance.get('/v1/chat/unread-messages', {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });
            return response.status === 200 ? await response.data.unread : false;
        } catch (error) {
            return false;
        }
    }

    static async sendBudgetRequest(shopId: number, serviceId: number, vehicleId: number, message: string) {
        const token = localStorage.getItem('token');
        if (!token) {
            return false;
        }

        try {
            const response = await axiosInstance.post('/v1/chat/create-topic', {
                shopId,
                serviceId,
                vehicleId,
                message,
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });
            return response.status === 200 ? await response.data : false;
        } catch (error) {
            return false;
        }
    }

    static async obtainFavoriteShops() {
        const token = localStorage.getItem('token');
        if (!token) {
            return false;
        }
        try {
            const response = await axiosInstance.get('/v1/user/favorite-shops', {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });
            return response.status === 200 ? await response.data as ShopProfile[] : false;
        } catch (error) {
            return false;
        }
    }

    static async obtainNotifications() {
        const token = localStorage.getItem('token');
        if (!token) {
            return false;
        }
        try {
            const response = await axiosInstance.get('/v1/user/notifications', {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });
            return response.status === 200 ? await response.data : false;
        } catch (error) {
            return false;
        }
    }

    static async setNotificationAsRead(notificationId: number) {
        const token = localStorage.getItem('token');
        if (!token) {
            return false;
        }
        try {
            const response = await axiosInstance.post('/v1/user/notification/read', {
                notificationId,
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });
            return response.status === 200;
        } catch (error) {
            return false;
        }
    }

    static async obtainUserPendingSchedules() {
        const token = localStorage.getItem('token');
        if (!token) {
            return false;
        }
        try {
            const response = await axiosInstance.get('/v1/user/schedules/pending', {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });
            return response.status === 200 ? await response.data as schedulePayload[] : false;
        } catch (error) {
            return false;
        }
    }

    static async obtainUserAcceptedSchedules() {
        const token = localStorage.getItem('token');
        if (!token) {
            return false;
        }
        try {
            const response = await axiosInstance.get('/v1/user/schedules/accepted', {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });
            return response.status === 200 ? await response.data as schedulePayload[] : false;
        } catch (error) {
            return false;
        }
    }

    static async obtainUserCompletedSchedules() {
        const token = localStorage.getItem('token');
        if (!token) {
            return false;
        }
        try {
            const response = await axiosInstance.get('/v1/user/schedules/completed', {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });
            return response.status === 200 ? await response.data as schedulePayload[] : false;
        } catch (error) {
            return false;
        }
    }

    static async acceptSchedule(scheduleId: number) {
        const token = localStorage.getItem('token');
        if (!token) {
            return false;
        }
        try {
            const response = await axiosInstance.get(`/v1/user/schedule/${scheduleId}/accept`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });
            return response.status === 200;
        } catch (error) {
            return false;
        }
    }

    static async rejectSchedule(scheduleId: number) {
        const token = localStorage.getItem('token');
        if (!token) {
            return false;
        }
        try {
            const response = await axiosInstance.get(`/v1/user/schedule/${scheduleId}/reject`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });
            return response.status === 200;
        } catch (error) {
            return false;
        }
    }
}