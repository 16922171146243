<template>
    <div class="col-12 services-offered">
        <h5 class="text-center mb-3">Localização</h5>
        <hr>

        <div class="mapContainer">
            <l-map ref="map" :zoom="zoom" :center="[shop.gps_lat, shop.gps_lon]" @update:center="mapMoved">
                <l-tile-layer
                    url="https://mt0.google.com/vt/hl=pt&x={x}&y={y}&z={z}&s=Ga"
                    layer-type="base"
                    name="GoogleMaps"
                    :options="{ zoomControl: true, attributionControl: true }"
                ></l-tile-layer>
                <l-marker :lat-lng="[shop.gps_lat, shop.gps_lon]">
                    <LIcon :iconUrl="require('@/assets/map_icons/repair_shop.png')" :iconSize="[64, 64]" :iconAnchor="[12, 41]" />
                </l-marker>
            </l-map>
        </div>
        <div class="row">
            <div class="col-12 pt-2 text-center mapinfolocation">
                <i class="fas fa-map-marker-alt"></i> {{ shop.address }} {{ shop.address2 }}, {{ shop.zip }} {{ shop.city }} {{ shop.state }}<br>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import "leaflet/dist/leaflet.css";
import { LMap, LIcon, LMarker, LTileLayer } from "@vue-leaflet/vue-leaflet";
import { defineComponent } from "vue";

export default defineComponent({
    components: {
        LMap,
        LTileLayer,
        LMarker,
        LIcon
    },
    props: {
        shop: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            zoom: 14,
            isResultOverMarker: 0,
            searchLat: 0,
            searchLng: 0,
        };
    }
});
</script>

<style scoped>
.mapContainer {
    height: 300px;
    width: 100%;
}


.services-offered {
    padding: 20px;
    background-color: white !important;
    border-radius: 10px;
    margin-top: 20px;
    overflow-y: auto;
    border: 1px solid #d7d7d7;
}

.mapinfolocation {
    color: var(--info-color);
    font-size: 14px;
}

</style>
