<template>
    <div v-if="isLoading" class="full-page-spinner">
      <div class="spinner"></div>
    </div>
    <div v-if="showSidebar">
        <div class="d-flex flex-column flex-shrink-0 p-3 sidebar-nav">
            <img v-if="shopProfile?.logo !== undefined && shopProfile?.logo !== null" :src="shopProfile?.logo" class="img-responsive" alt="Logo" style="width: 70%; margin-left: auto;margin-right:auto;margin-top:15px;">
            <hr>
            <ul class="nav nav-pills flex-column mb-auto">
                <li class="nav-item" v-for="(page, idx) in pages" v-bind:key="idx">
                    <div v-if="page.submenus && page.submenus.length > 0" class="nav-item dropdown">
                        <a href="#" class="nav-link dropdown-toggle" :class="{ active: $route.path.includes(page.route) }" @click.prevent="toggleDropdown(idx)" :aria-expanded="dropdowns[idx]">
                            {{ page.name }}
                        </a>
                        <transition name="fade">
                            <ul v-show="dropdowns[idx]" class="nav flex-column ms-3">
                                <li v-for="(submenu, subIdx) in page.submenus" v-bind:key="subIdx">
                                    <router-link v-if="page.roles.includes(userRole)" :to="submenu.route" class="nav-link" :class="{ active: $route.path.includes(submenu.route) }">{{ submenu.name }}</router-link>
                                </li>
                            </ul>
                        </transition>
                    </div>
                    <router-link v-else-if="page.roles.includes(userRole)" :to="page.route" class="nav-link" :class="{ active: $route.path.includes(page.route) }">{{ page.name }}</router-link>
                </li>
            </ul>
            <router-link to="/" class="d-flex align-items-center mb-3 me-md-auto text-decoration-none">
                <img src="/img/logobranco.png" class="img-responsive" alt="Logo" style="width: 60%;margin: auto;">
            </router-link>
            <small class="text-center mb-2" style="font-size: 10px; color: white;">
                &copy; 2024, Todos os direitos reservados
            </small>
        </div>
        <div>
            <div class="container-fluid topbar">
                <div class="text-right mr-2 pt-4">
                    Olá, {{ name }}! 
                    <button class="btn btn-sm btn-logout" @click="$router.push({ path: '/logout'})">Terminar sessão</button>
                </div>
            </div>
            <div class="content-wrapper">
                <div class="container-fluid shop-page-content mb-5">
                    <router-view />
                </div>
            </div>
        </div>
    </div>
</template>
<script lang="ts">
import { defineComponent, ref, watch } from 'vue'; // Import ref for reactivity
import { useRouter, useRoute } from 'vue-router'; // Import Vue Router hooks
import { AuthService } from '@/services/authService';
import { ShopInfo } from '@/dtos/shopService/shopInfoInterface';
import { ShopService } from '@/services/Shop/ShopService';
export default defineComponent({
    name: 'SideNavbar',
    methods: {
        toggleDropdown(idx: number) {
            this.dropdowns[idx] = !this.dropdowns[idx];
        },
    },
    created() {
        // Initialize dropdown states as closed (false) for all pages with submenus
        this.pages.forEach((page, idx) => {
        if (page.submenus && page.submenus.length > 0) {
            this.dropdowns[idx] = false;
        }
        });
    },
    data () {
        return {
            dropdowns: [] as boolean[],
            name: 'User',
            userRole: '',
            shopProfile: null as ShopInfo | null,
            pages: [
                { name: 'Perfil', route: '/shop/dashboard', roles: ['WORKER', 'OWNER', 'MANAGER', 'FINANCE'] },
                { name: 'Agenda', route: '/shop/schedule', roles: ['WORKER', 'OWNER', 'MANAGER', 'FINANCE'] },
                { name: 'Chat', route: '/shop/chat', roles: ['WORKER', 'OWNER', 'MANAGER', 'FINANCE'] },
                { name: 'Clientes', route: '/shop/clients', roles: ['OWNER', 'MANAGER', 'FINANCE'] },
                { name: 'Avaliações', route: '/shop/reviews', roles: ['OWNER', 'MANAGER']},
                { name: 'A sua Equipa', route: '/shop/team', roles: ['OWNER', 'MANAGER'] },
                { name: 'Gestor de Serviço', route: '/shop/service', roles: ['OWNER', 'MANAGER'], submenus: [
                    {
                        name: 'Todos os Serviços',
                        route: '/shop/service/all',
                    },
                    {
                        name: 'Serviços em Curso',
                        route: '/shop/service/ongoing',
                    },
                    {
                        name: 'Serviços finalizados',
                        route: '/shop/service/finished',
                    },
                ] },
                { name: 'Relatórios', route: '/shop/reports', roles: ['OWNER', 'MANAGER', 'FINANCE'] },
                { name: 'Pagamentos', route: '/shop/payments', roles: ['OWNER', 'MANAGER', 'FINANCE'] },
                { name: 'Histórico de Serviços', route: '/shop/service_history', roles: ['OWNER', 'MANAGER', 'FINANCE'] },
                { name: 'Campanhas', route: '/shop/campaigns', roles: ['OWNER', 'MANAGER', 'FINANCE'] },
                { name: 'Planos', route: '/shop/subscription', roles: ['OWNER', 'MANAGER', 'FINANCE'] },
            ],
        };
    },
    async mounted() {
        this.name = await AuthService.getUserName();
        this.userRole = await ShopService.getWorkerPermission();
        this.shopProfile = await ShopService.shopInfo() || null;
        // Listen for event updateLogo
        window.addEventListener('updateLogo', async () => {
            this.shopProfile = await ShopService.shopInfo() || null;
        });
    },
    setup() {
        const showSidebar = ref(false); // Reactive state for sidebar visibility
        const route = useRoute(); // Current route
        const router = useRouter(); // Router instance

        // Check authentication status when the component is mounted
        const checkAuthentication = async () => {
            showSidebar.value = await AuthService.isAuthenticated();
        };

        checkAuthentication(); // Call once on component mount

        // Watch for route changes
        watch(route, async () => {
            const isAuthenticated = await AuthService.isAuthenticated();

            if (!isAuthenticated) {
                showSidebar.value = false;
            } else {
                showSidebar.value = true;
            }
        });

        // Return reactive properties to the template
        return {
            showSidebar,
        };
    },
});
</script>

<style scoped>
/* Sidebar */
.sidebar-nav {
    width: 200px;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    padding: 0 !important;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: var(--primary-color) !important;
    /* Add box shadow black to the right */
    box-shadow: 0 0 10px 0 rgba(0,0,0,1);
}

.sidebar-nav .nav {
    padding: unset;
    margin: unset;
}

.nav-link {
    color: white !important;
    background-color: unset !important;
    transition: ease-in-out 0.3s;
    border: 1px solid var(--primary-color);
    border-radius: 5px !important;
}

.nav-link.active, .nav-link:hover {
    background-color: rgba(255,255,255,0.3) !important;
    color: white !important;
    border: 1px solid rgba(255,255,255,0.3) !important;
}

.nav-link.active {
    border: 1px solid white !important;
}

/* Content Area */
.content-container {
    margin-left: 250px;
    margin-top: 70px;
}

.btn-logout {
    background-color: white;
    color: var(--primary-color);
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    margin-left: 10px;
}

.btn-logout:hover {
    background-color: var(--primary-color);
    color: white;
}

.full-page-spinner {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 9999;
}

.spinner {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top: 4px solid var(--primary-color);
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.nav-item .nav-link {
    font-size: 12px;
}

.nav-item .dropdown-toggle {
  cursor: pointer;
}

.nav-item ul {
  list-style: none;
  padding-left: 0;
}

.ms-3 {
  margin-left: 1rem; /* Adjust submenu indent */
}
</style>
