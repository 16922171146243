import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

import LoginView from '@/views/authentication/Login.vue'
import RegisterView from '@/views/authentication/Register.vue'
import RecoverPassword from '@/views/authentication/RecoverPassword.vue'
import shopPage from '@/views/shop/profilePage.vue'

import HomeView from '../views/Home.vue'
import Index from '@/views/Index.vue'
import SearchView from '../views/search/mainSearch.vue'

import shopLoginView from '@/shop_views/authentication/login.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'index',
    component: Index
  },
  {
    path: '/dashboard',
    name: 'home',
    component: HomeView,
    meta: { requiresAuth: true, role: 'USER' } // Only USER role can access
  },
  {
    path: '/pesquisa/',
    name: 'pesquisa',
    component: SearchView,
    meta: { requiresAuth: true, role: 'USER' }
  },
  {
    path: '/establecimento/:id',
    name: 'establecimento',
    component: shopPage,
    meta: { requiresAuth: true, role: 'USER' }
  },
  {
    path: '/conta',
    name: 'profile',
    component: () => import('../views/user/profile.vue'),
    meta: { requiresAuth: true, role: 'USER' }
  },
  {
    path: '/conta/viaturas',
    name: 'profile.vehicles',
    component: () => import('../views/user/vehicles.vue'),
    meta: { requiresAuth: true, role: 'USER' }
  },
  {
    path: '/conta/viaturas/:id',
    name: 'profile.vehicles.details',
    component: () => import('../views/user/seeVehicle.vue'),
    meta: { requiresAuth: true, role: 'USER' }
  },
  {
    path: '/conta/viaturas/adicionar',
    name: 'profile.vehicles.add',
    component: () => import('../views/user/addNewVehicle.vue'),
    meta: { requiresAuth: true, role: 'USER' }
  },
  {
    path: '/chat',
    name: 'chat',
    component: () => import('../views/chat/chats.vue'),
    meta: { requiresAuth: true, role: 'USER' }
  },
  {
    path: '/chat/:id',
    name: 'chat.see',
    component: () => import('../views/chat/chats.vue'),
    meta: { requiresAuth: true, role: 'USER' }
  },
  {
    path: '/favoritos',
    name: 'favorites',
    component: () => import('../views/user/favorites.vue'),
  },
  {
    path: '/marcacoes/pendentes',
    name: 'schedules.pending',
    component: () => import('../views/user/schedules/schedulesPending.vue'),
  },
  {
    path: '/marcacoes/pendentes/:id',
    name: 'schedules.pending.marked',
    component: () => import('../views/user/schedules/schedulesPending.vue'),
  },
  {
    path: '/marcacoes/confirmadas',
    name: 'schedules.accepted',
    component: () => import('../views/user/schedules/schedulesConfirmed.vue'),
  },
  {
    path: '/marcacoes/finalizadas',
    name: 'schedules.completed',
    component: () => import('../views/user/schedules/schedulesCompleted.vue'),
  },
  {
    path: '/marcacoes/ver/:id',
    name: 'schedules.see',
    component: () => import('../views/user/schedules/seeSchedule.vue'),
  },
  {
    path: '/logout',
    name: 'logout',
    meta: { requiresAuth: true },
    component: { template: '<div>Logging out...</div>' }
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/register',
    name: 'register',
    component: RegisterView
  },
  {
    path: '/recover-password',
    name: 'recover-password',
    component: RecoverPassword
  },
  // SHOP ROUTES
  {
    path: '/shop/login',
    name: 'shop.login',
    component: shopLoginView
  },
  {
    path: '/shop/register',
    name: 'shop.register',
    component: () => import('../shop_views/authentication/register.vue')
  },
  {
    path: '/shop/dashboard',
    name: 'shop.dashboard',
    component: () => import('../shop_views/dashboard/dashboard.vue'),
    meta: { requiresAuth: true, role: 'SHOP' } // Only SHOP roles can access
  },
  {
    path: '/shop/schedule',
    name: 'shop.schedule',
    component: () => import('../shop_views/dashboard/agenda.vue'),
    meta: { requiresAuth: true, role: 'SHOP' } // Only SHOP roles can access
  },
  {
    path: '/shop/schedule/new',
    name: 'shop.schedule.new',
    component: () => import('../shop_views/dashboard/schedule/newSchedule.vue'),
    meta: { requiresAuth: true, role: 'SHOP' } // Only SHOP roles can access
  },
  {
    path: '/shop/schedule/:id',
    name: 'shop.schedule.view',
    component: () => import('../shop_views/dashboard/schedule/seeSchedule.vue'),
    meta: { requiresAuth: true, role: 'SHOP' } // Only SHOP roles can access
  },
  {
    path: '/shop/team',
    name: 'shop.team',
    component: () => import('../shop_views/dashboard/shopteam.vue'),
    meta: { requiresAuth: true, role: 'SHOP' } // Only SHOP roles can access
  },
  {
    path: '/shop/team/add',
    name: 'shop.team.add',
    component: () => import('../shop_views/dashboard/sub/addWorker.vue'),
    meta: { requiresAuth: true, role: 'SHOP' } // Only SHOP roles can access
  },
  {
    path: '/shop/team/:id',
    name: 'shop.team.edit',
    component: () => import('../shop_views/dashboard/sub/editWorker.vue'),
    meta: { requiresAuth: true, role: 'SHOP' } // Only SHOP roles can access
  },
  {
    path: '/shop/clients',
    name: 'shop.clients',
    component: () => import('../shop_views/dashboard/customers.vue'),
    meta: { requiresAuth: true, role: 'SHOP' } // Only SHOP roles can access
  },
  {
    path: '/shop/clients/add',
    name: 'shop.client.add',
    component: () => import('../shop_views/dashboard/customers/addCustomer.vue'),
    meta: { requiresAuth: true, role: 'SHOP' } // Only SHOP roles can access
  },
  {
    path: '/shop/clients/:id',
    name: 'shop.client.edit',
    component: () => import('../shop_views/dashboard/customers/editCustomer.vue'),
    meta: { requiresAuth: true, role: 'SHOP' } // Only SHOP roles can access
  },
  {
    path: '/shop/chat',
    name: 'shop.chat',
    component: () => import('../shop_views/dashboard/chat.vue'),
    meta: { requiresAuth: true, role: 'SHOP' } // Only SHOP roles can access
  },
  {
    path: '/shop/chat/:id',
    name: 'shop.chat.view',
    component: () => import('../shop_views/dashboard/chat/seeChat.vue'),
    meta: { requiresAuth: true, role: 'SHOP' } // Only SHOP roles can access
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    redirect: '/' // or redirect to '/'
  }  
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// Helper to decode and validate JWT token
function getToken() {
  const token = localStorage.getItem('token') || ""
  if (token === "") return null
  try {
    const decodedToken = JSON.parse(atob(token.split('.')[1]))
    const currentTime = Date.now() / 1000
    if (decodedToken.exp > currentTime) {
      return decodedToken
    }
    return null
  } catch (e) {
    return null
  }
}

router.beforeEach((to, from, next) => {
  const token = getToken()

  // Handle logout route
  if (to.name === 'logout') {
    localStorage.removeItem('token')
    window.location.href = '/login'
    return
  }

  // Check if route requires authentication
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!token) {
      next('/login') // Redirect to login if not authenticated
      return
    }

    const userRole = token.role

    // Handle USER-specific routes
    if (to.matched.some(record => record.meta.role === 'USER')) {
      if (userRole === 'USER') {
        next()
      } else if (userRole.startsWith('SHOP_')) {
        // Redirect SHOP users to shop dashboard
        next('/shop/dashboard')
      } else {
        next('/login') // Redirect to login if no matching role
      }
      return
    }

    // Handle SHOP-specific routes
    if (to.matched.some(record => record.meta.role === 'SHOP')) {
      if (userRole.startsWith('SHOP_')) {
        next()
      } else if (userRole === 'USER') {
        // Redirect USER users to home
        next('/dashboard')
      } else {
        next('/login') // Redirect to login if no matching role
      }
      return
    }
  }

  // Prevent logged-in users from accessing auth pages (login/register)
  if (['login', 'register', 'shop.login'].includes(to.name as string)) {
    if (token) {
      if (token.role.startsWith('SHOP_')) {
        next('/shop/dashboard') // Redirect SHOP users to dashboard
      } else if (token.role === 'USER') {
        next('/dashboard') // Redirect USER users to home
      } else {
        next('/login')
      }
      return
    }
  }

  next() // Always call next() to proceed with navigation
})

// Periodic token expiration check
setInterval(() => {
  const token = getToken()
  if (!token) {
    localStorage.removeItem('token')
    // redirect to login page if user is in protected route
    if (router.currentRoute.value.meta.requiresAuth) {
      window.location.href = '/login'
    }
  }
}, 1000)

export default router
