<template>
    <footer class="mt-5" v-if="showFooter()">
        <div class="container">
            <div class="row">
                <div class="col-12 col-lg-4 col-xl-4 col-xxl-4">
                    <div class="row">
                        <div class="col-12 text-center">
                            <img src="/img/logobranco.png" alt="Logo" class="img-fluid logo"><br>
                            Descarregue já a APP DriverWise!<br>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 text-center">
                            <img src="/img/download_google.png" alt="Google Play" class="img-fluid downloadbtns">
                            <img src="/img/download_apple.svg" alt="Apple Store" class="img-fluid downloadbtns apple">
                        </div>
                    </div>
                </div>
                <div class="col-12 col-lg-3 col-xl-3 col-xxl-3 offset-lg-1 offset-xl-1 offset-xxl-1 mt-5">
                    <div class="mt-3">
                        <a href="#">Apoio ao cliente</a><br>
                        <a href="#">Termos e condições</a><br>
                        <a href="#">Política de Privacidade</a><br>
                        <a href="#">Livro de reclamações eletrónico</a>
                    </div>
                </div>
                <div class="col-12 col-lg-2 offset-lg-2 col-xl-2 offset-xl-2 col-xxl-2 offset-xxl-2 mt-5">
                    <div class="mt-3">
                        <a href="#">Sobre nós</a><br>
                        <a href="#">Contactos</a><br>
                        <a href="#">FAQ</a><br>
                        <a href="#">Blog & Newsletter</a>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 text-center mb-2 mt-2 copyrightText">
                    DriverWise &copy; 2024 - Todos os direitos reservados
                </div>
            </div>
        </div>
    </footer>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'FooterComponent',
    methods: {
        showFooter() {
            const token = localStorage.getItem('token');
            const path = this.$route.path;
            return !token || path === '/' || path === '/login' || path === '/shop/login' || path === '/register' || path === '/shop/register';
        }
    },
    data() {
        return {
            resizeListener: null as (() => void) | null
        };
    }
})
</script>
<style scoped>
h1, h2, h3, h4, h5, p, span, a {
    color: white !important;
}

a:hover {
    color: var(--primary-color) !important;
}

.copyrightText {
    font-size: 12px;
    color: #AFAFAF;
}

footer {
    min-height: 200px;
    background-color: #333;
    padding: 20px;
    color: white;
}
footer img.logo {
    width: 300px;
}

footer img.downloadbtns {
    width: 150px;
    text-align: center !important;
}

footer img.downloadbtns.apple {
    width: 130px;
}


</style>