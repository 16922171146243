import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import sweetalert from './plugins/sweetalert';
import { VueReCaptcha } from 'vue-recaptcha-v3';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue-next/dist/bootstrap-vue-next.css';
import '@/assets/style.css';
import 'sweetalert2/dist/sweetalert2.min.css';


createApp(App)
    .use(store)
    .use(router)
    .use(sweetalert)
    .use(VueReCaptcha, {
        siteKey: process.env.VUE_APP_RECAPTCHA_SITE_KEY || '',
        loaderOptions: {
            useRecaptchaNet: true,
            autoHideBadge: false
        }
    })
    .mount('#app')
