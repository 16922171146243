<template>
    <div class="jumbotron jumbotron-fluid">
        <div class="container-fluid">
            <div class="row">
                <div class="col-xxl-8 col-xl-8 col-12 offset-xxl-2 offset-xl-2 pb-5">
                    <h1 class="d-none d-xl-block">Está a um passo de explorer o<br>potencial DriverWise!</h1>
                    <h2 class="d-block d-xl-none">Está a um passo de explorer o<br>potencial DriverWise!</h2>
                    <h3>Faça hoje o seu registo!</h3>
                </div>
            </div>
        </div>
    </div>
    <div class="container login mt-5 mb-5 pb-5 pt-5">
        <div class="row">
            <div class="col-12 text-center">
                <h2 style="font-size: 24px; font-weight: bolder;"><b>Registo</b></h2>
                <h5>Crie a sua conta agora</h5>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-xxl-4 col-xl-4 col-lg-4 col-md-6 offset-xxl-4 offset-xl-4 offset-md-2">
                <form method="post" @submit.prevent="signup">
                    <div class="form-floating mt-3">
                        <input type="text" class="form-control" id="name" placeholder="Primeiro Nome" v-model="firstName" required>
                        <label for="year">Primeiro Nome <span class="text-danger">*</span></label>
                    </div>
                    <div class="form-floating mt-3">
                        <input type="text" class="form-control" id="name" placeholder="Último Nome" v-model="lastName" required>
                        <label for="year">Último Nome <span class="text-danger">*</span></label>
                    </div>
                    <div class="form-floating mt-3">
                        <input type="email" required class="form-control" id="name" placeholder="Endereço de E-mail" v-model="email">
                        <label for="year">Endereço de E-mail <span class="text-danger">*</span></label>
                    </div>
                    <div class="form-floating mt-3">
                        <input type="password" class="form-control" id="name" placeholder="Palavra passe" v-model="password" @input="evaluatePasswordStrength" required>
                        <label for="year">Palavra Passe <span class="text-danger">*</span></label>
                    </div>
                    <Transition name="fade">
                        <div class="password-strength mt-1">
                            <div class="strength-bar" :style="{'background-color': barClasses[0]}"></div>
                            <div class="strength-bar" :style="{'background-color': barClasses[1]}"></div>
                            <div class="strength-bar" :style="{'background-color': barClasses[2]}"></div>
                            <div class="strength-bar" :style="{'background-color': barClasses[3]}"></div>
                        </div>
                    </Transition>
                    <div class="password-strength mt-2" :class="passwordStrengthClass">
                        {{ passwordStrengthMessage }}
                    </div>
                    <div class="form-floating mt-3">
                        <input type="password" class="form-control" id="name" placeholder="Repita a Palavra passe" v-model="repeatPassword" required>
                        <label for="year">Repita a Palavra Passe <span class="text-danger">*</span></label>
                    </div>
                    <div class="form-floating mt-3 text-center">
                        <button type="submit" class="btn btn-primary btn-lg">Registar</button>
                    </div>
                    <hr>
                    <p class="text-muted mt-2 text-center">
                        Já têm uma conta?<br>
                        <router-link to="/login">Iniciar sessão</router-link>
                    </p>
                </form>
            </div>
        </div>
    </div>
</template>
<script lang="ts">
import { AuthService } from '@/services/authService';
import { UserService } from '@/services/userService';

import { defineComponent } from 'vue';

export default defineComponent({
  name: 'loginView',
  data: function() {
    return {
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        repeatPassword: '',
        loggingIn: false,
        passwordStrength: '',
        barClasses: ['gray', 'gray', 'gray', 'gray']
    }
  },
  mounted() {
    document.title = 'DriverWise - Iniciar Sessão';
  },
  computed: {
    passwordStrengthClass() {
      switch(this.passwordStrength) {
        case 'Fraca':
          return 'text-danger';
        case 'Normal':
          return 'text-warning';
        case 'Forte':
          return 'text-success';
        default:
          return '';
      }
    },
    passwordStrengthMessage() {
      switch(this.passwordStrength) {
        case 'Fraca':
          return 'Senha fraca';
        case 'Normal':
          return 'Senha normal';
        case 'Forte':
          return 'Senha forte';
        default:
          return '';
      }
    }
  },
  methods: {
    evaluatePasswordStrength() {
        const password = this.password;
        let strength = 0;

        if (password.length >= 6) strength++;
        if (password.match(/[A-Za-z]/) && password.match(/[0-9]/)) strength++;
        if (password.length > 8) strength++;
        if (password.match(/[A-Z]/) && password.match(/[^A-Za-z0-9]/)) strength++;

        this.passwordStrength = ['Fraca', 'Normal', 'Forte', 'Forte'][strength - 1] || 'Fraca';

        // Update bar colors based on strength
        this.barClasses = this.barClasses.map((_, index) => {
            if (index < strength) {
                switch(strength) {
                    case 1:
                        return 'red';
                    case 2:
                        return 'yellow';
                    case 3:
                    case 4:
                        return 'green';
                    default:
                        return 'gray';
                }
            } else {
                return 'gray';
            }
        });
    },
    onPasswordInputUpdated(password: string) {
      this.password = password;
    },
    onEmailInputUpdated(email: string) {
      this.email = email;
    },
    signup() {
        if (this.password !== this.repeatPassword) {
            this.$swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'As palavras passe não coincidem!',
            });
            return;
        }

        if (this.password.trim().length < 6) {
            this.$swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'A palavra passe deve ter no mínimo 8 caracteres!',
            });
            return;
        }

        this.$swal.fire({
            html: '<div class="container-fluid text-center pt-5 pb-5"><div class="spinner"></div></div>',
            showConfirmButton: false,
            allowOutsideClick: false,
            backdrop: true,
        });

        AuthService.register(this.firstName, this.lastName, this.email, this.password).then((response: number) => {
            switch(response) {
                case 0:
                    this.$swal.fire({
                        icon: 'success',
                        title: 'Sucesso!',
                        text: 'Conta criada com sucesso!',
                        timer: 2000,
                        showConfirmButton: false,
                        allowOutsideClick: false
                    });
                    this.$router.push('/login');
                    break;
                case 1:
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Ocorreu um erro ao criar a conta!',
                        confirmButtonColor: '#EA7C69',
                    });
                    break;
                case 2:
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'O email já está em uso!',
                        confirmButtonColor: '#EA7C69',
                    });
                    break;
            }
        })
    }
  },
});
</script>
<style>
.password-strength {
    display: flex;
    justify-content: space-between;
}

.strength-bar {
    flex: 1;
    height: 8px;
    margin: 0 2px;
    background-color: gray;
    transition: ease-in-out 0.3s;
}

.red {
    background-color: red;
}

.yellow {
    background-color: yellow;
}

.green {
    background-color: green;
}

.password-strength-text {
    font-size: 0.9em;
}
.jumbotron h1 {
    font-size: 3rem;
}
</style>